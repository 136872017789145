.section-title-col{

  //Checkbox Field in a Section Title (list section)
  .field-checkbox-label{
   margin-right: 8px; 
  }
  .field-checkbox{
    padding-left: 0;
    padding-top: 8px;
  }  
}

.list-section{
  .section-header{
    .section-title-col{
      .section-title{
        color   : #757575;
      }
    }
  }
}